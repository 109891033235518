import { reactive } from 'vue';
import {
  type AssistantSetting,
  type ColorPaletteSetting,
  type IWorkspaceSetting,
} from '@cdm/@shared-server-notebook/types/workspace-setting';
import { workspaceApiClient } from '@cdm/clients/WorkspaceSettingApiClient';
import { Message } from '@cdm/components/arco/Message';
import { StoreManager } from '@cdm/utils/StoreManager';
import { TypedEventEmitter } from '@cdm/utils/TypedEventEmitter';

export type WorkspaceSetting = Pick<IWorkspaceSetting, 'assistant' | 'color_palette'>;

type WorkspaceSettingStoreEvents = {
  workspaceSettingUpdated: (setting: WorkspaceSetting) => void;
};

class WorkspaceSettingStore extends TypedEventEmitter<WorkspaceSettingStoreEvents> {
  public state: {
    initialized: boolean;
    loading: boolean;
    setting: WorkspaceSetting | null;
  } = {
    initialized: false,
    loading: false,
    setting: null,
  };

  constructor() {
    super();
    this.state = reactive(this.state);
    this.loadWorkspaceSetting();
  }

  private async loadWorkspaceSetting() {
    this.state.loading = true;

    try {
      const res = await workspaceApiClient.getWorkspaceSetting();
      this.state.setting = res;
      this.emit('workspaceSettingUpdated', res);
      this.state.initialized = true;
    } catch (err: any) {
      console.error(err);
      Message.error(err.message || 'error occured. please try again later');
    } finally {
      this.state.loading = false;
    }
  }

  public async updateAssistantSetting(newSettings: AssistantSetting) {
    if (!this.state.setting) return;
    this.state.loading = true;

    try {
      const res = await workspaceApiClient.updateAssistantSetting(newSettings);
      if (res) {
        this.state.setting.assistant = res;
        this.emit('workspaceSettingUpdated', this.state.setting);
        Message.success('Updated successfully');
      }
    } catch (err: any) {
      console.error(err);
      Message.error(err.message || 'error occured. please try again later');
    } finally {
      this.state.loading = false;
    }
  }

  public async updateColorPaletteSetting(newSettings: ColorPaletteSetting) {
    if (!this.state.setting) return;
    this.state.loading = true;

    try {
      const res = await workspaceApiClient.updateColorPaletteSetting(newSettings);
      if (res) {
        this.state.setting.color_palette = res;
        this.emit('workspaceSettingUpdated', this.state.setting);
        Message.success('Updated successfully');
      }
    } catch (err: any) {
      console.error(err);
      Message.error(err.message || 'error occured. please try again later');
    } finally {
      this.state.loading = false;
    }
  }
}

const manager = new StoreManager(WorkspaceSettingStore);

export const getWorkspaceSettingStore = manager.get;
export const destroyWorkspaceSettingStore = manager.destroy;
