import EventEmitter from 'events';

// EventEmitter のイベントの型を強制する版(型定義だけ強制上書き)
// 以下を参考（ほぼコピー…）に実装
// https://github.com/binier/tiny-typed-emitter

export type ListenerSignature<L> = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [E in keyof L]: (...args: any[]) => any;
};

// @ts-expect-error 型を無理やり上書き
export declare interface TypedEventEmitter<L extends ListenerSignature<L>> {
  defaultMaxListeners: number;
  addListener<U extends keyof L>(event: U, listener: L[U]): this;
  prependListener<U extends keyof L>(event: U, listener: L[U]): this;
  prependOnceListener<U extends keyof L>(event: U, listener: L[U]): this;
  removeListener<U extends keyof L>(event: U, listener: L[U]): this;
  removeAllListeners(event?: keyof L): this;
  once<U extends keyof L>(event: U, listener: L[U]): this;
  on<U extends keyof L>(event: U, listener: L[U]): this;
  off<U extends keyof L>(event: U, listener: L[U]): this;
  emit<U extends keyof L>(event: U, ...args: Parameters<L[U]>): boolean;
  eventNames<U extends keyof L>(): U[];
  listenerCount(type: keyof L): number;
  listeners<U extends keyof L>(type: U): L[U][];
  rawListeners<U extends keyof L>(type: U): L[U][];
  getMaxListeners(): number;
  setMaxListeners(n: number): this;
}

// @ts-expect-error 型を無理やり上書き
export class TypedEventEmitter extends EventEmitter {}
