import type { IconDefinition } from '@fortawesome/fontawesome-svg-core';

export const faColumns2: IconDefinition = {
  prefix: 'fak',
  iconName: 'columns-2' as any,
  icon: [
    640,
    512,
    [],
    'e000',
    'M344 80v352h232c8.8 0 16-7.2 16-16V96c0-8.8-7.2-16-16-16H344zm-48 352V80H64c-8.8 0-16 7.2-16 16v320c0 8.8 7.2 16 16 16h232zM0 96c0-35.3 28.7-64 64-64h512c35.3 0 64 28.7 64 64v320c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96z',
  ],
};

export const faColumns4: IconDefinition = {
  prefix: 'fak',
  iconName: 'columns-4' as any,
  icon: [
    640,
    512,
    [],
    'e001',
    'M576 32c35.3 0 64 28.7 64 64v320c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96c0-35.3 28.7-64 64-64h512zm0 48h-83v352h83c8.8 0 16-7.2 16-16V96c0-8.8-7.2-16-16-16zm-429 0H64c-8.8 0-16 7.2-16 16v320c0 8.8 7.2 16 16 16h83V80zm148 0h-99v352h99V80zm149 0h-99v352h99V80z',
  ],
};

export const faColumns5: IconDefinition = {
  prefix: 'fak',
  iconName: 'columns-5' as any,
  icon: [
    640,
    512,
    [],
    'e002',
    'M576 32c35.3 0 64 28.7 64 64v320c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96c0-35.3 28.7-64 64-64h512zm0 48h-54v352h54c8.8 0 16-7.2 16-16V96c0-8.8-7.2-16-16-16zm-458 0H64c-8.8 0-16 7.2-16 16v320c0 8.8 7.2 16 16 16h54V80zm120 0h-73v352h73V80zm118 0h-73v352h73V80zm119 0h-73v352h73V80z',
  ],
};

export const faColumnMinus: IconDefinition = {
  prefix: 'fak',
  iconName: 'column-minus' as any,
  icon: [
    640,
    512,
    [],
    'e003',
    'M274 1c37.003 0 67 29.997 67 67v30.21a176.411 176.411 0 0 0-47.002 32.907L294 68c0-11.046-8.954-20-20-20H146c-11.046 0-20 8.954-20 20v377c0 11.046 8.954 20 20 20h128c11.046 0 20-8.954 20-20l-.002-62.117A176.411 176.411 0 0 0 341 415.79V445c0 37.003-29.998 67-67.001 67H146c-37.003 0-67-29.997-67-67V68c0-37.003 29.997-67 67-67h128Zm143 112c79.529 0 144 64.471 144 144s-64.471 144-144 144-144-64.471-144-144 64.471-144 144-144Zm74 128H343c-8.8 0-16 7.2-16 16s7.2 16 16 16h148c8.8 0 16-7.2 16-16s-7.2-16-16-16Z',
  ],
};

export const faColumnPlus: IconDefinition = {
  prefix: 'fak',
  iconName: 'column-plus' as any,
  icon: [
    640,
    512,
    [],
    'e004',
    'M274 1c37.003 0 67 29.997 67 67v30.21a176.411 176.411 0 0 0-47.002 32.907L294 68c0-11.046-8.954-20-20-20H146c-11.046 0-20 8.954-20 20v377c0 11.046 8.954 20 20 20h128c11.046 0 20-8.954 20-20l-.002-62.117A176.411 176.411 0 0 0 341 415.79V445c0 37.003-29.998 67-67.001 67H146c-37.003 0-67-29.997-67-67V68c0-37.003 29.997-67 67-67h128Zm143 112c79.529 0 144 64.471 144 144s-64.471 144-144 144-144-64.471-144-144 64.471-144 144-144Zm0 54c-8.8 0-16 7.2-16 16v58h-58c-8.8 0-16 7.2-16 16s7.2 16 16 16h58v58c0 8.8 7.2 16 16 16s16-7.2 16-16v-58h58c8.8 0 16-7.2 16-16s-7.2-16-16-16h-58v-58c0-8.8-7.2-16-16-16Z',
  ],
};

export const faRowMinus: IconDefinition = {
  prefix: 'fak',
  iconName: 'row-minus' as any,
  icon: [
    640,
    512,
    [],
    'e005',
    'M319 209c79.529 0 144 64.471 144 144s-64.471 144-144 144-144-64.471-144-144 64.471-144 144-144Zm74 128H245c-8.8 0-16 7.2-16 16s7.2 16 16 16h148c8.8 0 16-7.2 16-16s-7.2-16-16-16ZM508 15c37.003 0 67 29.997 67 67v128c0 37.003-29.997 67-67 67h-30.21a176.411 176.411 0 0 0-32.907-47.002L508 230c11.046 0 20-8.954 20-20V82c0-11.046-8.954-20-20-20H131c-11.046 0-20 8.954-20 20v128c0 11.046 8.954 20 20 20l62.117-.002A176.411 176.411 0 0 0 160.21 277H131C93.997 277 64 247.003 64 210V82c0-37.003 29.997-67 67-67h377Z',
  ],
};

export const faRowPlus: IconDefinition = {
  prefix: 'fak',
  iconName: 'row-plus' as any,
  icon: [
    640,
    512,
    [],
    'e006',
    'M319 209c79.529 0 144 64.471 144 144s-64.471 144-144 144-144-64.471-144-144 64.471-144 144-144Zm0 54c-8.8 0-16 7.2-16 16v58h-58c-8.8 0-16 7.2-16 16s7.2 16 16 16h58v58c0 8.8 7.2 16 16 16s16-7.2 16-16v-58h58c8.8 0 16-7.2 16-16s-7.2-16-16-16h-58v-58c0-8.8-7.2-16-16-16ZM508 15c37.003 0 67 29.997 67 67v128c0 37.003-29.997 67-67 67h-30.21a176.411 176.411 0 0 0-32.907-47.002L508 230c11.046 0 20-8.954 20-20V82c0-11.046-8.954-20-20-20H131c-11.046 0-20 8.954-20 20v128c0 11.046 8.954 20 20 20l62.117-.002A176.411 176.411 0 0 0 160.21 277H131C93.997 277 64 247.003 64 210V82c0-37.003 29.997-67 67-67h377Z',
  ],
};

export const faExternalTable: IconDefinition = {
  prefix: 'fak',
  iconName: 'external-table' as any,
  icon: [
    576,
    512,
    [],
    'e007',
    'M476,-3.55271368e-15 C511.3,-3.55271368e-15 540,28.7 540,64 L540,384 C540,419.3 511.3,448 476,448 L92,448 C56.7,448 28,419.3 28,384 L28,321 L76,321 L76,384 C76,392.8 83.2,400 92,400 L476,400 C484.8,400 492,392.8 492,384 L492,128 L76,128 L76,207 L28,207 L28,64 C28,61.8 28.1,59.6 28.3,57.5 C29.2,48.9 31.8,40.8 35.7,33.5 C41.4,23.1 49.8,14.5 60,8.5 C67.7,4.1 76.3,1.2 85.5,0.3 C87.6,0.1 89.8,-3.55271368e-15 92,-3.55271368e-15 L476,-3.55271368e-15 L476,-3.55271368e-15 Z M233.1,168 C242.4,158.6 257.6,158.6 267,168 L267,167.9 L347,247.9 C356.4,257.2 356.4,272.4 347,281.8 L267,361.8 C257.7,371.2 242.5,371.1 233.1,361.8 C223.7,352.5 223.7,337.3 233.1,327.9 L272.1,288.9 L24,288.9 C10.7,288.9 0,278.2 0,264.9 C0,251.6 10.7,240.9 24,240.9 L272.1,240.9 L233.1,201.9 C223.7,192.6 223.8,177.4 233.1,168 Z',
  ],
};

export const faMaterializedView: IconDefinition = {
  prefix: 'fak',
  iconName: 'materialized-view' as any,
  icon: [
    576,
    512,
    [],
    'e008',
    'M24,96 C37.3,96 48,106.7 48,120 L48,376 C48,424.6 87.4,464 136,464 L456,464 C469.3,464 480,474.7 480,488 C480,501.3 469.3,512 456,512 L136,512 C60.9,512 0,451.1 0,376 L0,120 C0,106.7 10.7,96 24,96 Z M512,0 C547.3,0 576,28.7 576,64 L576,352 C576,387.3 547.3,416 512,416 L160,416 C124.7,416 96,387.3 96,352 L96,64 C96,28.7 124.7,0 160,0 L512,0 Z M144,352 C144,360.8 151.2,368 160,368 L312,368 L312,272 L144,272 L144,352 Z M512,368 C520.8,368 528,360.8 528,352 L528,272 L360,272 L360,368 L512,368 Z M144,128 L144,224 L312,224 L312,128 L144,128 Z M360,224 L528,224 L528,128 L360,128 L360,224 Z',
  ],
};

export const faSidebar: IconDefinition = {
  prefix: 'fak',
  iconName: 'sidebar' as any,
  icon: [
    576,
    512,
    [],
    'e009',
    'M506.423631,32 C544.847262,32 576,63.5 576,102.349888 L576,409.650112 C576,448.500224 544.847262,480 506.423631,480 L69.5768123,480 C31.1538462,480 0,448.500224 0,409.650112 L0,102.349888 C0,83.099776 7.61538462,65.6874944 19.9903902,53 C32.6249945,40.0500224 50.1921969,32 69.5768123,32 L506.423631,32 Z M506.423631,81.262528 L221.538462,81.262528 L221.538462,430.737472 L506.423631,430.737472 C517.934769,430.737472 527.279262,421.28736 527.279262,409.650112 L527.279262,102.349888 C527.279262,90.71264 517.934769,81.262528 506.423631,81.262528 Z M177.230769,81.262528 L69.5768123,81.262528 C58.0674462,81.262528 48.7211815,90.71264 48.7211815,102.349888 L48.7211815,409.650112 C48.7211815,421.28736 58.0674462,430.737472 69.5768123,430.737472 L177.230769,430.737472 L177.230769,81.262528 Z',
  ],
};

export const faMinimize: IconDefinition = {
  prefix: 'fak',
  iconName: 'minimize' as any,
  icon: [
    576,
    512,
    [],
    'e010',
    'M506.436923,32 C544.856123,32 576,63.4905024 576,102.336 L576,409.664 C576,448.509632 544.856123,480 506.436923,480 L69.5630769,480 C31.1444529,480 0,448.509632 0,409.664 L0,102.336 C0,63.4904576 31.1444529,32 69.5630769,32 L506.436923,32 Z M506.436923,81.28 L69.5630769,81.28 C58.0621292,81.28 48.7384615,90.707264 48.7384615,102.336 L48.7384615,409.664 C48.7384615,421.292736 58.0621292,430.72 69.5630769,430.72 L88.6153846,430.72 L88.6153846,368 L310.153846,368 L310.153846,430.72 L506.436923,430.72 C517.9392,430.72 527.261538,421.292736 527.261538,409.664 L527.261538,102.336 C527.261538,90.707264 517.9392,81.28 506.436923,81.28 Z',
  ],
};

export const faFloat: IconDefinition = {
  prefix: 'fak',
  iconName: 'float' as any,
  icon: [
    576,
    512,
    [],
    'e011',
    'M506.436923,32 C544.856123,32 576,63.4905024 576,102.336 L576,409.664 C576,448.509632 544.856123,480 506.436923,480 L69.5630769,480 C31.1444529,480 0,448.509632 0,409.664 L0,102.336 C0,63.4904576 31.1444529,32 69.5630769,32 L506.436923,32 Z M506.436923,81.28 L69.5630769,81.28 C58.0621292,81.28 48.7384615,90.707264 48.7384615,102.336 L48.7384615,409.664 C48.7384615,421.292736 58.0621292,430.72 69.5630769,430.72 L506.436923,430.72 C517.9392,430.72 527.261538,421.292736 527.261538,409.664 L527.261538,102.336 C527.261538,90.707264 517.9392,81.28 506.436923,81.28 Z M265.846154,144 L265.846154,368 L110.769231,368 L110.769231,144 L265.846154,144 Z',
  ],
};

export const faFullscreen: IconDefinition = {
  prefix: 'fak',
  iconName: 'fullscreen' as any,
  icon: [
    576,
    512,
    [],
    'e012',
    'M506.423631,32 C544.847262,32 576,63.5 576,102.349888 L576,409.650112 C576,448.500224 544.847262,480 506.423631,480 L69.5768123,480 C31.1538462,480 0,448.500224 0,409.650112 L0,102.349888 C0,63.5 31.1538462,32 69.5768123,32 L506.423631,32 Z M506.423631,81.262528 L69.5768123,81.262528 C58.0674462,81.262528 48.7211815,90.71264 48.7211815,102.349888 L48.7211815,409.650112 C48.7211815,421.28736 58.0674462,430.737472 69.5768123,430.737472 L506.423631,430.737472 C517.934769,430.737472 527.279262,421.28736 527.279262,409.650112 L527.279262,102.349888 C527.279262,90.71264 517.934769,81.262528 506.423631,81.262528 Z M465.35052,144.121081 L465.35052,368.121081 L110.888981,368.121081 L110.888981,144.121081 L465.35052,144.121081 Z',
  ],
};
