import { colorPaletteStore } from '@cdm/libs/color-palette';

const LS_KEY = 'cdm-theme-preference';

export const THEME_PREFERENCES = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
  SYSTEM: 'SYSTEM',
} as const;

export type ThemePreference = typeof THEME_PREFERENCES[keyof typeof THEME_PREFERENCES];

export const THEME_PREFERENCE_OPTIONS: { label: string; value: ThemePreference }[] = [
  { label: 'Light theme', value: THEME_PREFERENCES.LIGHT },
  { label: 'Dark theme', value: THEME_PREFERENCES.DARK },
  { label: 'Sync with system', value: THEME_PREFERENCES.SYSTEM },
];

// localStorage を更新せずに現在のテーマ設定を変更
let instantThemeIsDark: boolean | null = null;

export const setInstantTheme = (t: ThemePreference): void => {
  let isDark = false;
  if (t === THEME_PREFERENCES.SYSTEM) {
    isDark = window.matchMedia('(prefers-color-scheme: dark)').matches === true;
  } else if (t === THEME_PREFERENCES.DARK) {
    isDark = true;
  }
  instantThemeIsDark = isDark;
  if (isDark) {
    document.body.setAttribute('color-scheme', 'dark');
  } else {
    document.body.removeAttribute('color-scheme');
  }
  colorPaletteStore.setTheme(isDark ? 'DARK' : 'LIGHT');
};

export const useThemePreference = () => {
  const getThemePreference = (): ThemePreference => {
    const pref = (window.localStorage.getItem(LS_KEY) as ThemePreference) || null;
    return pref || THEME_PREFERENCES.SYSTEM;
  };

  const setThemePreference = (t: ThemePreference): void => {
    window.localStorage.setItem(LS_KEY, t);
    window.location.reload();
  };

  const applyThemePreference = (): void => {
    const isDark = getIsDark();
    if (isDark) {
      document.body.setAttribute('color-scheme', 'dark');
    } else {
      document.body.removeAttribute('color-scheme');
    }
    colorPaletteStore.setTheme(isDark ? 'DARK' : 'LIGHT');
  };

  const getIsDark = (): boolean => {
    if (instantThemeIsDark !== null) {
      // setInstantTheme 経由で指定されている場合はそちらを優先する
      return instantThemeIsDark;
    }
    const pref = getThemePreference();
    if (pref === THEME_PREFERENCES.SYSTEM) {
      return window.matchMedia('(prefers-color-scheme: dark)').matches === true;
    } else if (pref === THEME_PREFERENCES.DARK) {
      return true;
    }
    return false;
  };

  return {
    getIsDark,
    getThemePreference,
    setThemePreference,
    applyThemePreference,
    THEME_PREFERENCE_OPTIONS,
  };
};
