/**
 * mongo の ObjectId っぽい文字列を生成
 * @returns string
 */
export const generateObjectId = (): string => {
  const hex = (value: number): string => {
    return Math.floor(value).toString(16);
  };
  return hex(Date.now() / 1000) + ' '.repeat(16).replace(/./g, () => hex(Math.random() * 16));
};
