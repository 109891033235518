import type { App } from 'vue';
import {
  TRACKER_MODULE_TYPES,
  type IdentifyProps,
  type IdentifyPropsWithCompany,
  type TrackerModule,
} from '../types';

export class GoogleAnalyticsTracker implements TrackerModule {
  moduleType = TRACKER_MODULE_TYPES.GOOGLE_ANALYTICS;
  gtag: any | undefined;
  constructor(googleAnalyticsId: string) {
    this.insertGoogleAnalyticsTag(googleAnalyticsId);
    this.gtag = (window as any).gtag;
  }
  init(app: App<any>): void {
    // do nothing
  }
  identifyWithCompany({ id, name, email, company }: IdentifyPropsWithCompany): void {
    // do nothing
  }
  view(): void {
    // do nothing
  }
  track(event: string, properties: any) {
    // do nothing
  }
  openChat(): void {
    // do nothing
  }
  identify({ id, name, email, intercomHash }: IdentifyProps): void {
    this.gtag?.('event', 'identify', {
      user_id: id,
    });
  }

  private insertGoogleAnalyticsTag(googleAnalyticsId: string) {
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`;
    document.head.appendChild(script);
    // @ts-ignore
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      // @ts-ignore
      // eslint-disable-next-line prefer-rest-params
      dataLayer.push(arguments);
    }
    (window as any).gtag = gtag;
    this.gtag = (window as any).gtag;
    this.gtag?.('js', new Date());
    this.gtag?.('config', googleAnalyticsId);
  }
}
