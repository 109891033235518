<script setup lang="ts">
import { ref, onErrorCaptured, defineSlots, computed } from 'vue';
import { ApiError as NodeApiError } from '@cdm/@shared-server-notebook/endpoints/client';
import ApiError from '@cdm/components/error/ApiError.vue';
import { ApiError as GoApiError } from '@cdm/clients/fetcher';
import { logError } from '@cdm/utils/logger';

defineSlots<{
  default(): any;
  error(props: { error: Error }): any;
}>();

const errorCaptured = ref<Error | GoApiError | NodeApiError | null>(null);

onErrorCaptured(_error => {
  logError(_error);
  errorCaptured.value = _error;
  return false;
});

const isApiError = computed(() => {
  return errorCaptured.value instanceof GoApiError || errorCaptured.value instanceof NodeApiError;
});
</script>

<template>
  <template v-if="errorCaptured">
    <ApiError v-if="isApiError" :error="errorCaptured" />
    <slot v-else name="error" :error="errorCaptured" />
  </template>
  <slot v-else />
</template>
