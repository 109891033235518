import { createRouter, createWebHistory, type RouteRecordRaw } from 'vue-router';
import tracker from '../../tracker';
import { urlChangedEvent } from '@cdm/events/urlChanged';

const PostAuthTop = () => import('@cdm/pages/PostAuthTop.vue');

// middlewares
const RequireAdmin = () => import('@cdm/entries/default/middlewares/RequireAdmin.vue');
const RequireSession = () => import('@cdm/entries/default/middlewares/RequireSession.vue');
const RequireAccount = () => import('@cdm/entries/default/middlewares/RequireAccount.vue');
const RequireWorkspace = () => import('@cdm/entries/default/middlewares/RequireWorkspace.vue');

// domains/entrance
const Login = () => import('@cdm/domains/entrance/pages/login/index.vue');
const Signup = () => import('@cdm/domains/entrance/pages/signup/index.vue');

// domains/preWorkspace
const EmailVerification = () =>
  import('@cdm/domains/preWorkspace/pages/signup/email-verification/EmailVerification.vue');
const RegisterPassword = () =>
  import('@cdm/domains/preWorkspace/pages/signup/register-password/RegisterPassword.vue');
const RegisterProfile = () =>
  import('@cdm/domains/preWorkspace/pages/signup/register-profile/RegisterProfile.vue');
const WorkspaceSetup = () => import('@cdm/domains/preWorkspace/pages/WorkspaceSetup.vue');
const AccountAuthAction = () => import('@cdm/domains/preWorkspace/pages/auth-action/index.vue');
const SelectWorkspace = () =>
  import('@cdm/domains/preWorkspace/pages/workspace/SelectWorkspace.vue');
const CreateWorkspace = () =>
  import('@cdm/domains/preWorkspace/pages/workspace/CreateWorkspace.vue');
const SetupInviteMembers = () => import('@cdm/domains/preWorkspace/pages/invite-members/index.vue');
const SetupSelectDataSource = () =>
  import('@cdm/domains/preWorkspace/pages/select-data-source/index.vue');
const SetupCreateConnection = () =>
  import('@cdm/domains/preWorkspace/pages/create-connection/index.vue');
const SetupSyncConnection = () =>
  import('@cdm/domains/preWorkspace/pages/sync-connection/index.vue');
const WorkspaceInvitation = () => import('@cdm/domains/preWorkspace/pages/invitation/index.vue');
const InviteLinkJoin = () =>
  import('@cdm/domains/preWorkspace/pages/invitation/InviteLinkJoin.vue');
const ForceEmailVerification = () =>
  import('@cdm/domains/preWorkspace/pages/force-email-verification/ForceEmailVerification.vue');

// domains/workspace
const InWorkspaceLayout = () => import('@cdm/components/layout/InWorkspaceLayout.vue');
const WorkspaceTop = () => import('@cdm/domains/workspace/pages/top/WorkspaceTop.vue');
const WorkspaceSettingLayout = () => import('@cdm/domains/workspace/pages/setting/index.vue');
const WorkspaceSettingSettings = () => import('@cdm/domains/workspace/pages/setting/Settings.vue');
const WorkspaceSettingGeneral = () =>
  import('@cdm/domains/workspace/pages/setting/general/General.vue');
const WorkspaceSettingMembers = () =>
  import('@cdm/domains/workspace/pages/setting/members/index.vue');
const WorkspaceSettingApiKeys = () =>
  import('@cdm/domains/workspace/pages/setting/apiKeys/index.vue');
const WorkspaceSettingConnections = () =>
  import('@cdm/domains/workspace/pages/setting/connections/index.vue');
const WorkspaceSettingTags = () => import('@cdm/domains/workspace/pages/setting/tags/index.vue');
const WorkspaceSettingPlans = () => import('@cdm/domains/workspace/pages/setting/plans/Plans.vue');
const WorkspaceSettingSecurity = () =>
  import('@cdm/domains/workspace/pages/setting/security/Security.vue');
const WorkspaceSettingUsage = () => import('@cdm/domains/workspace/pages/setting/usage/Usage.vue');
const WorkspaceSettingBilling = () =>
  import('@cdm/domains/workspace/pages/setting/billing/Billing.vue');

// domains/account
const AccountSetting = () => import('@cdm/domains/account/pages/settings/AccountSetting.vue');

// domains/notebook
const NotebookLayout = () => import('@cdm/domains/notebook/pages/NotebookLayout.vue');
const NotebookTop = () => import('@cdm/domains/notebook/pages/top/NotebookTop.vue');
const NotebookEach = () => import('@cdm/domains/notebook/pages/each/index.vue');
const NotebookVersionEach = () => import('@cdm/domains/notebook/pages/each/version_index.vue');
const NotebookList = () => import('@cdm/domains/notebook/pages/list/index.vue');
const NotebookIntegrationPreview = () =>
  import('@cdm/domains/notebook/pages/each/integration/IntegrationPreviewPage.vue');

// domains/report
const ReportList = () => import('@cdm/domains/report/pages/list/index.vue');
const ReportEach = () => import('@cdm/domains/report/pages/each/index.vue');

// domains/search
const Search = () => import('@cdm/domains/search/pages/Search/Search.vue');

// domains/query
const QueryLayout = () => import('@cdm/domains/query/pages/QueryLayout.vue');

// domains/catalog
const CatalogWrapper = () => import('@cdm/domains/catalog/pages/CatalogWrapper.vue');
const CatalogTable = () => import('@cdm/domains/catalog/pages/Catalog/Catalog.vue');
const CatalogQuery = () => import('@cdm/domains/catalog/pages/CatalogQuery/Catalog.vue');
const CatalogDetail = () => import('@cdm/domains/catalog/pages/CatalogDetail/CatalogDetail.vue');

// domains/admin
const AdminLayout = () => import('@cdm/domains/admin/pages/index.vue');
const AdminWorkspaces = () => import('@cdm/domains/admin/pages/workspace/index.vue');
const AdminDebug = () => import('@cdm/domains/admin/pages/debug/index.vue');
const AdminAccounts = () => import('@cdm/domains/admin/pages/account/index.vue');

// domains/globalLink = public
const GlobalNotebook = () => import('@cdm/domains/globalLink/pages/GlobalNotebookView.vue');
const GlobalNotebookChart = () =>
  import('@cdm/domains/globalLink/pages/GlobalNotebookChartView.vue');

// domains/integration = protected
const IntegratedNotebook = () =>
  import('@cdm/domains/integration/pages/IntegratedNotebookView.vue');

// error page
const NotFoundError = () => import('@cdm/pages/NotFoundError.vue');

export const ROUTE_NAMES = {
  NOT_FOUND: 'NOT_FOUND',
  LOGIN: 'LOGIN',
  SIGNUP: 'SIGNUP',
  POSTAUTH_TOP: 'POSTAUTH_TOP',
  REGISTER_PROFILE: 'REGISTER_PROFILE',
  ACCOUNT_AUTH_ACTION: 'ACCOUNT_AUTH_ACTION',
  ACCOUNT_SETTING: 'ACCOUNT_SETTING',
  EMAIL_VERIFICATION: 'EMAIL_VERIFICATION',
  FORCE_EMAIL_VERIFICATION: 'FORCE_EMAIL_VERIFICATION',
  REGISTER_PASSWORD: 'REGISTER_PASSWORD',
  SELECT_WORKSPACE: 'SELECT_WORKSPACE',
  CREATE_WORKSPACE: 'CREATE_WORKSPACE',
  WORKSPACE_TOP: 'WORKSPACE_TOP',
  SETUP_WORKSPACE: 'SETUP_WORKSPACE',
  SETUP_INVITE_MEMBERS: 'SETUP_INVITE_MEMBERS',
  SETUP_SELECT_DATA_SOURCE: 'SETUP_SELECT_DATA_SOURCE',
  SETUP_CREATE_CONNECTION: 'SETUP_CREATE_CONNECTION',
  SETUP_SYNC_CONNECTION: 'SETUP_SYNC_CONNECTION',
  WORKSPACE_SETTING_PARENT: 'WORKSPACE_SETTING_PARENT',
  WORKSPACE_SETTING: 'WORKSPACE_SETTING',
  WORKSPACE_SETTING_GENERAL: 'WORKSPACE_SETTING_GENERAL',
  WORKSPACE_SETTING_MEMBER: 'WORKSPACE_SETTING_MEMBER',
  WORKSPACE_SETTING_API_KEY: 'WORKSPACE_SETTING_API_KEY',
  WORKSPACE_SETTING_CONNECTION: 'WORKSPACE_SETTING_CONNECTION',
  WORKSPACE_SETTING_CONNECTION_DETAIL: 'WORKSPACE_SETTING_CONNECTION_DETAIL',
  WORKSPACE_SETTING_TAG: 'WORKSPACE_SETTING_TAG',
  WORKSPACE_SETTING_PLAN: 'WORKSPACE_SETTING_PLAN',
  WORKSPACE_SETTING_SECURITY: 'WORKSPACE_SETTING_SECURITY',
  WORKSPACE_SETTING_USAGE: 'WORKSPACE_SETTING_USAGE',
  WORKSPACE_SETTING_BILLING: 'WORKSPACE_SETTING_BILLING',
  WORKSPACE_INVITATION: 'WORKSPACE_INVITATION',
  WORKSPACE_INVITE_LINK_JOIN: 'WORKSPACE_INVITE_LINK_JOIN',
  SEARCH: 'SEARCH',
  NOTEBOOK_PARENT: 'NOTEBOOK_PARENT',
  NOTEBOOK_TOP: 'NOTEBOOK_TOP',
  NOTEBOOK_LIST: 'NOTEBOOK_LIST',
  NOTEBOOK_EACH: 'NOTEBOOK_EACH',
  NOTEBOOK_VERSION_EACH: 'NOTEBOOK_VERSION_EACH',
  NOTEBOOK_INTEGRATION_PREVIEW: 'NOTEBOOK_INTEGRATION_PREVIEW',
  REPORT_LIST: 'REPORT_LIST',
  REPORT_EACH: 'REPORT_EACH',
  QUERY_PARENT: 'QUERY_PARENT',
  CATALOG_PARENT: 'CATALOG_PARENT',
  CATALOG_TABLE: 'CATALOG_TABLE',
  CATALOG_TABLE_DETAIL: 'CATALOG_TABLE_DETAIL',
  CATALOG_QUERY: 'CATALOG_QUERY',
  ADMIN: 'ADMIN',
  ADMIN_DEBUG: 'ADMIN_DEBUG',
  ADMIN_ACCOUNTS: 'ADMIN_ACCOUNTS',
  GLOBAL_NOTEBOOK_PAGE: 'GLOBAL_NOTEBOOK_PAGE',
  GLOBAL_NOTEBOOK_CHART_PAGE: 'GLOBAL_NOTEBOOK_CHART_PAGE',
  INTEGRATED_NOTEBOOK: 'INTEGRATED_NOTEBOOK',
} as const;

const routes: RouteRecordRaw[] = [
  {
    name: ROUTE_NAMES.LOGIN,
    path: '/login',
    component: Login,
    meta: {
      title: 'Sign In',
    },
  },
  {
    name: ROUTE_NAMES.SIGNUP,
    path: '/signup',
    component: Signup,
    meta: {
      title: 'Sign Up',
    },
  },
  {
    name: ROUTE_NAMES.EMAIL_VERIFICATION,
    path: '/signup/email_verification',
    component: EmailVerification,
    meta: {
      title: 'Email Verification',
    },
  },
  {
    name: ROUTE_NAMES.REGISTER_PASSWORD,
    path: '/signup/register_password',
    component: RegisterPassword,
    meta: {
      title: 'Register Password',
    },
  },
  // firebase authからのリダイレクト先path
  {
    name: ROUTE_NAMES.ACCOUNT_AUTH_ACTION,
    path: '/account/__/action',
    component: AccountAuthAction,
  },
  // invite mail
  // TODO: wsIdはpathから除いて良さそう
  {
    name: ROUTE_NAMES.WORKSPACE_INVITATION,
    path: '/invitation/:wsId/activate/:token',
    component: WorkspaceInvitation,
    meta: {
      title: 'Invitation',
    },
  },
  {
    path: '/',
    component: RequireSession,
    children: [
      // ログイン後かつアカウント未作成時にアクセスするページ
      {
        name: ROUTE_NAMES.REGISTER_PROFILE,
        path: '/signup/register_profile',
        component: RegisterProfile,
        meta: {
          title: 'Register Profile',
        },
      },
      // ログイン後にemail verifyしていない場合に強制的に飛ばされるページ. アカウント作成前に必ずemail verifyするフローなので、問題がなければこのページは表示されない
      {
        name: ROUTE_NAMES.FORCE_EMAIL_VERIFICATION,
        path: '/account/force_email_verification',
        component: ForceEmailVerification,
        meta: {
          title: 'Email Verification',
        },
      },
      // ログイン後のページ
      {
        path: '/',
        component: RequireAccount,
        children: [
          {
            name: ROUTE_NAMES.POSTAUTH_TOP,
            path: '/',
            component: PostAuthTop,
          },
          {
            name: ROUTE_NAMES.SELECT_WORKSPACE,
            path: '/workspace',
            component: SelectWorkspace,
            meta: {
              title: 'Select Workspace',
            },
          },
          {
            name: ROUTE_NAMES.CREATE_WORKSPACE,
            path: '/workspace/new',
            component: CreateWorkspace,
            meta: {
              title: 'Create Workspace',
            },
          },
          // invite link
          {
            name: ROUTE_NAMES.WORKSPACE_INVITE_LINK_JOIN,
            path: '/join/:code',
            component: InviteLinkJoin,
            meta: {
              title: 'Invitation',
            },
          },
          {
            path: '/workspace/:wsId',
            component: RequireWorkspace,
            props: true,
            children: [
              // Workspace top
              {
                name: ROUTE_NAMES.WORKSPACE_TOP,
                path: '/workspace/:wsId',
                component: WorkspaceTop,
              },
              // Workspace setup
              {
                name: ROUTE_NAMES.SETUP_WORKSPACE,
                path: '/workspace/:wsId/setup',
                component: WorkspaceSetup,
                children: [
                  {
                    name: ROUTE_NAMES.SETUP_INVITE_MEMBERS,
                    path: '/workspace/:wsId/setup/invite-members',
                    component: SetupInviteMembers,
                    meta: {
                      title: 'Invite members',
                    },
                  },
                  {
                    name: ROUTE_NAMES.SETUP_SELECT_DATA_SOURCE,
                    path: '/workspace/:wsId/setup/select-data-source',
                    component: SetupSelectDataSource,
                    meta: {
                      title: 'Select data source',
                    },
                  },
                  {
                    name: ROUTE_NAMES.SETUP_CREATE_CONNECTION,
                    path: '/workspace/:wsId/setup/create-connection',
                    component: SetupCreateConnection,
                    meta: {
                      title: 'Create connection',
                    },
                  },
                  {
                    name: ROUTE_NAMES.SETUP_SYNC_CONNECTION,
                    path: '/workspace/:wsId/setup/sync-connection/:connId',
                    component: SetupSyncConnection,
                    meta: {
                      title: 'Sync with your data',
                    },
                  },
                ],
              },
              {
                name: ROUTE_NAMES.REPORT_EACH,
                path: '/workspace/:wsId/report/:reportId/:pageId',
                component: ReportEach,
                meta: {
                  // title は ReportEach.vue で設定
                },
              },
              // 通常のレイアウトのページ
              {
                path: '/workspace/:wsId',
                component: InWorkspaceLayout,
                children: [
                  {
                    name: ROUTE_NAMES.ACCOUNT_SETTING,
                    path: '/workspace/:wsId/account/setting/:tab',
                    component: AccountSetting,
                    meta: {
                      title: 'Account settings',
                    },
                  },
                  {
                    name: ROUTE_NAMES.WORKSPACE_SETTING_PARENT,
                    path: '/workspace/:wsId/setting/',
                    component: WorkspaceSettingLayout,
                    children: [
                      {
                        name: ROUTE_NAMES.WORKSPACE_SETTING_GENERAL,
                        path: '/workspace/:wsId/setting/general',
                        component: WorkspaceSettingGeneral,
                        meta: {
                          title: 'Workspace settings',
                        },
                      },
                      {
                        name: ROUTE_NAMES.WORKSPACE_SETTING_MEMBER,
                        path: '/workspace/:wsId/setting/members',
                        component: WorkspaceSettingMembers,
                        meta: {
                          title: 'Workspace settings',
                        },
                      },
                      {
                        name: ROUTE_NAMES.WORKSPACE_SETTING_API_KEY,
                        path: '/workspace/:wsId/setting/apiKeys',
                        component: WorkspaceSettingApiKeys,
                        meta: {
                          title: 'API Keys',
                        },
                      },
                      {
                        name: ROUTE_NAMES.WORKSPACE_SETTING_CONNECTION,
                        path: '/workspace/:wsId/setting/connections',
                        component: WorkspaceSettingConnections,
                        meta: {
                          title: 'Workspace settings',
                        },
                      },
                      {
                        name: ROUTE_NAMES.WORKSPACE_SETTING_CONNECTION_DETAIL,
                        path: '/workspace/:wsId/setting/connections/:connection',
                        component: WorkspaceSettingConnections,
                        meta: {
                          title: 'Workspace settings',
                        },
                      },
                      {
                        name: ROUTE_NAMES.WORKSPACE_SETTING_TAG,
                        path: '/workspace/:wsId/setting/tags',
                        component: WorkspaceSettingTags,
                        meta: {
                          title: 'Workspace settings',
                        },
                      },
                      {
                        name: ROUTE_NAMES.WORKSPACE_SETTING_PLAN,
                        path: '/workspace/:wsId/setting/plans',
                        component: WorkspaceSettingPlans,
                        meta: {
                          title: 'Workspace settings',
                        },
                      },
                      {
                        name: ROUTE_NAMES.WORKSPACE_SETTING_SECURITY,
                        path: '/workspace/:wsId/setting/security',
                        component: WorkspaceSettingSecurity,
                        meta: {
                          title: 'Workspace settings',
                        },
                      },
                      {
                        name: ROUTE_NAMES.WORKSPACE_SETTING_USAGE,
                        path: '/workspace/:wsId/setting/usage',
                        component: WorkspaceSettingUsage,
                        meta: {
                          title: 'Usage',
                        },
                      },
                      {
                        name: ROUTE_NAMES.WORKSPACE_SETTING_BILLING,
                        path: '/workspace/:wsId/setting/billing',
                        component: WorkspaceSettingBilling,
                        meta: {
                          title: 'Billing',
                        },
                      },
                      {
                        name: ROUTE_NAMES.WORKSPACE_SETTING,
                        path: '/workspace/:wsId/setting/:tab',
                        component: WorkspaceSettingSettings,
                        meta: {
                          title: 'Workspace settings',
                        },
                      },
                    ],
                  },
                  {
                    name: ROUTE_NAMES.SEARCH,
                    path: '/workspace/:wsId/search/',
                    component: Search,
                    meta: {
                      title: 'Search',
                    },
                  },
                  {
                    name: ROUTE_NAMES.NOTEBOOK_PARENT,
                    path: '/workspace/:wsId/notebook/',
                    component: NotebookLayout,
                    children: [
                      {
                        name: ROUTE_NAMES.NOTEBOOK_TOP,
                        path: '/workspace/:wsId/notebook/top',
                        component: NotebookTop,
                        meta: {
                          title: 'Notebooks',
                        },
                      },
                      {
                        name: ROUTE_NAMES.NOTEBOOK_LIST,
                        path: '/workspace/:wsId/notebook/',
                        component: NotebookList,
                        meta: {
                          // title は NotebookList.vue で設定
                        },
                      },
                    ],
                  },
                  {
                    name: ROUTE_NAMES.NOTEBOOK_EACH,
                    path: '/workspace/:wsId/notebook/:notebookId/:pageId',
                    component: NotebookEach,
                    meta: {
                      // title は NotebookEach.vue で設定
                    },
                  },
                  {
                    name: ROUTE_NAMES.NOTEBOOK_VERSION_EACH,
                    path: '/workspace/:wsId/notebook/:notebookId/version/:versionId/:pageId',
                    component: NotebookVersionEach,
                    meta: {
                      // title は NotebookVersionEach.vue で設定
                    },
                  },
                  {
                    name: ROUTE_NAMES.NOTEBOOK_INTEGRATION_PREVIEW,
                    path: '/workspace/:wsId/notebook/:notebookId/integration/:integrationId',
                    component: NotebookIntegrationPreview,
                    meta: {
                      // title は NotebookIntegrationPreview.vue で設定
                    },
                  },
                  {
                    name: ROUTE_NAMES.REPORT_LIST,
                    path: '/workspace/:wsId/report/',
                    component: ReportList,
                    meta: {
                      title: 'Reports',
                    },
                  },
                  {
                    name: ROUTE_NAMES.QUERY_PARENT,
                    path: '/workspace/:wsId/query/:page',
                    component: QueryLayout,
                    meta: {
                      title: 'Queries',
                    },
                  },
                  {
                    name: ROUTE_NAMES.CATALOG_PARENT,
                    path: '/workspace/:wsId/catalog/',
                    component: CatalogWrapper,
                    children: [
                      {
                        name: ROUTE_NAMES.CATALOG_TABLE,
                        path: '/workspace/:wsId/catalog/table/',
                        component: CatalogTable,
                        meta: {
                          title: 'Catalog',
                        },
                      },
                      {
                        name: ROUTE_NAMES.CATALOG_QUERY,
                        path: '/workspace/:wsId/catalog/query/',
                        component: CatalogQuery,
                        meta: {
                          title: 'Catalog',
                        },
                      },
                      {
                        name: ROUTE_NAMES.CATALOG_TABLE_DETAIL,
                        path: '/workspace/:wsId/catalog/table/:schemaUri/:tableId',
                        component: CatalogDetail,
                        props: true,
                        meta: {
                          title: 'Catalog',
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: '/_/',
            component: RequireAdmin,
            children: [
              {
                path: '/_/',
                component: AdminLayout,
                children: [
                  {
                    name: ROUTE_NAMES.ADMIN,
                    path: '/_/',
                    component: AdminWorkspaces,
                  },
                  {
                    name: ROUTE_NAMES.ADMIN_DEBUG,
                    path: '/_/debug',
                    component: AdminDebug,
                  },
                  {
                    name: ROUTE_NAMES.ADMIN_ACCOUNTS,
                    path: '/_/accounts',
                    component: AdminAccounts,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: ROUTE_NAMES.GLOBAL_NOTEBOOK_PAGE,
    path: '/public/workspace/:wsId/link/:globalLinkId/notebook/:notebookId/:pageId/',
    component: GlobalNotebook,
    props: true,
    meta: {
      // title は GlobalNotebookView.vue で設定
    },
  },
  {
    name: ROUTE_NAMES.GLOBAL_NOTEBOOK_CHART_PAGE,
    path: '/public/workspace/:wsId/link/:globalLinkId/chart/:pageId/:chartId/',
    component: GlobalNotebookChart,
    props: true,
    meta: {
      // title は GlobalNotebookChartView.vue で設定
    },
  },
  {
    name: ROUTE_NAMES.INTEGRATED_NOTEBOOK,
    path: '/protected/workspace/:wsId/notebook/:integrationId',
    component: IntegratedNotebook,
    meta: {
      // title は IntegratedNotebookView.vue で設定
    },
  },
  {
    name: ROUTE_NAMES.NOT_FOUND,
    path: '/:pathMatch(.*)*',
    component: NotFoundError,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.afterEach(() => {
  urlChangedEvent.emit({});
  tracker.view();
});

export default router;
