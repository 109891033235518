import type { User, Unsubscribe } from 'firebase/auth';

import { ApiClient } from '@cdm/@generated-account-client/auth-client';
import { computed, ref } from 'vue';
import { QueryClient } from '@tanstack/query-core';
import type { TokenClaims } from '@cdm/@generated-account-client/shared-types';
import { getFirebaseAuth, signOut, getFirebaseUser } from '@cdm/libs/firebase-auth';
import { createFetcher } from '@cdm/clients/fetcher';

const accountAuthClient = new ApiClient(createFetcher());

const firebaseUserState = {
  user: ref<User | null>(null),
  loading: ref<boolean>(true),
};

let firebaseUid: string | null = null;
let stopWatchUser: Unsubscribe | null = null; // stopするのは画面リロードする直前のみ

function watchUser() {
  stopWatchUser = getFirebaseAuth().onAuthStateChanged(_user => {
    // login userが切り替わった場合は強制リロード
    if (firebaseUid && _user?.uid !== firebaseUid) {
      location.reload();
      return;
    }

    if (_user) {
      firebaseUid = _user.uid;
    }

    firebaseUserState.user.value = _user;
    firebaseUserState.loading.value = false;
  });
}

let alreadyWatching = false;
export function initializeFirebaseUser() {
  if (!alreadyWatching) {
    alreadyWatching = true;
    watchUser();
  }
}

function getUser() {
  return firebaseUserState.user.value;
}

export function useFirebaseUser() {
  const firebaseUser = computed(() => getUser());
  const isLoading = computed(() => firebaseUserState.loading.value);

  async function reloadFirebaseUser() {
    if (firebaseUser.value) {
      await firebaseUser.value.reload();
      firebaseUserState.user.value = firebaseUser.value;
    }
  }

  return { firebaseUser, isLoading, reloadFirebaseUser };
}

async function waitAndGetUser() {
  const user = getUser();
  if (user) {
    return user;
  }

  return await getFirebaseUser();
}

export async function getIdToken(forceRefresh?: boolean) {
  const user = await waitAndGetUser();
  return await user.getIdToken(forceRefresh);
}

export async function checkExistAccount() {
  const user = await waitAndGetUser();
  const tokenResult = await user.getIdTokenResult(true);
  return !!tokenResult.claims['accountId'];
}

export function logout() {
  stopWatchUser?.();
  signOut();
  location.href = '/login';
}

const queryClient = new QueryClient();
export const getToken = async (workspaceId?: string | null) => {
  const accessToken = await queryClient.fetchQuery({
    queryKey: ['firebase-auth', 'issueToken', workspaceId],
    queryFn: async () => {
      const user = await waitAndGetUser();
      const idToken = await user.getIdToken();
      const { accessToken } = await accountAuthClient.auth_issueToken({
        idToken,
        workspaceId: workspaceId || undefined,
      });
      return accessToken;
    },
    staleTime: 1000 * 60 * 5,
  });

  return accessToken;
};

export const decodeJwt = (token: string): TokenClaims => {
  const payload = token.split('.')[1];
  const base64 = payload.replace(/-/g, '+').replace(/_/g, '/');
  const binString = window.atob(base64);
  const bytes = Uint8Array.from(binString, char => char.charCodeAt(0));
  const decoder = new TextDecoder();
  const raw = decoder.decode(bytes);
  return JSON.parse(raw);
};
