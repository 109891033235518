import type { EngineConnection, EngineType } from '@cdm/@shared-server-notebook/types/engine';
import type { ResourceSyncJob } from '@cdm/@generated-data-client/shared-types';
import logo_bq from '@cdm/assets/images/connection/logo_bq.png';
import logo_redshift from '@cdm/assets/images/connection/logo_redshift.png';

type valueOf<T> = T[keyof T];

export const CONNECTION_TYPES = {
  BIGQUERY: 'BIGQUERY',
  REDSHIFT: 'REDSHIFT',
} as const;

export type ConnectionType = valueOf<typeof CONNECTION_TYPES>;

export const CONNECTION_DEFS: {
  [key in ConnectionType]: {
    name: string;
    fullName: string;
    icon: string;
    adminOnly?: boolean;
  };
} = {
  BIGQUERY: {
    name: 'BigQuery',
    fullName: 'Google BigQuery',
    icon: logo_bq,
  },
  REDSHIFT: {
    name: 'Redshift',
    fullName: 'Amazon Redshift',
    icon: logo_redshift,
    adminOnly: true,
  },
};

export const CONNECTION_ACCESS_LEVELS = {
  PUBLIC: 'PUBLIC',
  PROTECTED: 'PROTECTED',
  PRIVATE: 'PRIVATE',
  UNKNOWN: 'UNKNOWN',
} as const;

export type ConnectionAccessLevel = valueOf<typeof CONNECTION_ACCESS_LEVELS>;

export const CONNECTION_ACCESS_LEVEL_OPTIONS: { value: ConnectionAccessLevel; label: string }[] = [
  { value: CONNECTION_ACCESS_LEVELS.PUBLIC, label: 'Workspace' },
  { value: CONNECTION_ACCESS_LEVELS.PROTECTED, label: 'Protected' },
  { value: CONNECTION_ACCESS_LEVELS.PRIVATE, label: 'Private' },
];

export const getAccessLevelDisplay = (accessLevel: ConnectionAccessLevel): string => {
  return (
    CONNECTION_ACCESS_LEVEL_OPTIONS.find(option => option.value === accessLevel)?.label || 'Unknown'
  );
};

const CONNECTION_ACCESS_LEVEL_DESCRIPTIONS: { [key in ConnectionAccessLevel]?: string } = {
  PUBLIC: 'All users can access workspace connections.',
  PROTECTED:
    'Only granted users can access protected connections. Workspace owners can edit permissions.',
  PRIVATE:
    'Only granted users can access private connections. Workspace owners cannot edit permissions.',
};

export const getAccessLevelDescription = (accessLevel: ConnectionAccessLevel): string => {
  return CONNECTION_ACCESS_LEVEL_DESCRIPTIONS[accessLevel] || '';
};

export type ConnectionSelectOptions = {
  label: string;
  help?: string;
  options: { label: string; value: string; engineType: EngineType; canExecute: boolean }[];
}[];
export const getConnectionSelectOptions = (
  connections: EngineConnection[],
  currentConnId: string | null = null,
): ConnectionSelectOptions => {
  const groups = [
    CONNECTION_ACCESS_LEVELS.PUBLIC,
    CONNECTION_ACCESS_LEVELS.PROTECTED,
    CONNECTION_ACCESS_LEVELS.PRIVATE,
  ].map(
    accessLevel =>
      ({
        label: getAccessLevelDisplay(accessLevel),
        help: getAccessLevelDescription(accessLevel),
        options: [],
      } as ConnectionSelectOptions[number]),
  );
  connections.forEach(conn => {
    const group = groups.find(group => group.label === getAccessLevelDisplay(conn.accessLevel));
    if (!group) return;
    group.options.push({
      label: conn.name,
      value: conn._id,
      engineType: conn.engineType,
      canExecute: conn.canExecute,
    });
  });
  if (currentConnId && !connections.find(c => c._id === currentConnId)) {
    groups.push({
      label: 'Unknown',
      options: [
        {
          label: '(Unauthorized or deleted connection)',
          value: currentConnId,
          engineType: 'UNKNOWN',
          canExecute: false,
        },
      ],
    });
  }
  return groups.filter(g => g.options.length > 0);
};

export const SYNC_JOB_STATUS_DEFS: {
  [key in ResourceSyncJob['status']]: {
    label: string;
    color: string;
  };
} = {
  PENDING: {
    label: 'QUEUED',
    color: 'var(--cdm-color-text-2)',
  },
  RUNNING: {
    label: 'PROCESSING',
    color: 'var(--cdm-color-text-2)',
  },
  SUCCESS: {
    label: 'SUCCESS',
    color: 'var(--cdm-color-text-7)',
  },
  WARNING: {
    label: 'WARNING',
    color: 'var(--cdm-color-text-5)',
  },
  ERROR: {
    label: 'FAILED',
    color: 'var(--cdm-color-text-3)',
  },
};
