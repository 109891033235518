import type { ApiRoutes } from '../common/api';
import type {
  AssistantSetting,
  ColorPaletteSetting,
  IWorkspaceSetting,
} from '../../types/workspace-setting';

export interface WorkspaceSettingApiInterfaces {
  getWorkspaceSetting(): Promise<Pick<IWorkspaceSetting, 'assistant' | 'color_palette'>>;

  updateAssistantSetting(assistantSetting: AssistantSetting): Promise<AssistantSetting>;
  updateColorPaletteSetting(colorPaletteSetting: ColorPaletteSetting): Promise<ColorPaletteSetting>;
}

export const workspaceSettingRoutes: ApiRoutes<WorkspaceSettingApiInterfaces> = {
  getWorkspaceSetting: '/setting/getWorkspaceSetting',

  updateAssistantSetting: '/setting/updateAssistantSetting',
  updateColorPaletteSetting: '/setting/updateColorPaletteSetting',
};
