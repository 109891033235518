import type { BuiltinColorPaletteKey } from '../types/color_palette';

export const BUILTIN_COLOR_PALETTE_KEYS = {
  DEFAULT: 'DEFAULT',
  MONOCHROME: 'MONOCHROME',
  PASTEL: 'PASTEL',
  AQUA: 'AQUA',
  EARTH: 'EARTH',
  MUTED: 'MUTED',
  VIVID: 'VIVID',
} as const;

export const BUILTIN_COLOR_PALETTE_OPTIONS: { key: BuiltinColorPaletteKey; label: string }[] = [
  { key: BUILTIN_COLOR_PALETTE_KEYS.DEFAULT, label: 'Default' },
  { key: BUILTIN_COLOR_PALETTE_KEYS.MONOCHROME, label: 'Monochrome' },
  { key: BUILTIN_COLOR_PALETTE_KEYS.PASTEL, label: 'Pastel' },
  { key: BUILTIN_COLOR_PALETTE_KEYS.AQUA, label: 'Aqua' },
  { key: BUILTIN_COLOR_PALETTE_KEYS.EARTH, label: 'Earth' },
  { key: BUILTIN_COLOR_PALETTE_KEYS.MUTED, label: 'Muted' },
  { key: BUILTIN_COLOR_PALETTE_KEYS.VIVID, label: 'Vivid' },
];

export const PALETTE_COLOR_NAMES = {
  SUCCESS: 'SUCCESS',
  WARN: 'WARN',
  DANGER: 'DANGER',

  SERIES1: 'SERIES1',
  SERIES2: 'SERIES2',
  SERIES3: 'SERIES3',
  SERIES4: 'SERIES4',
  SERIES5: 'SERIES5',
  SERIES6: 'SERIES6',
  SERIES7: 'SERIES7',
  SERIES8: 'SERIES8',
  SERIES9: 'SERIES9',
  SERIES10: 'SERIES10',
  SERIES11: 'SERIES11',
  SERIES12: 'SERIES12',

  GRADIENT_HIGH: 'GRADIENT_HIGH',
  GRADIENT_MID: 'GRADIENT_MID',
  GRADIENT_LOW: 'GRADIENT_LOW',
} as const;

export const PALETTE_THEMES = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
} as const;
