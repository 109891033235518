import { getApiClient } from '../@shared-server-notebook/endpoints/client';
import { workspaceSettingRoutes } from '../@shared-server-notebook/endpoints/implements/workspaceSetting';
import { GlobalConfig } from '@cdm/configs';
import AccountStore from '@cdm/domains/account/shared/stores/AccountStore';
import { getToken } from '@cdm/libs/auth';

export const workspaceApiClient = getApiClient(workspaceSettingRoutes, {
  baseUrl: `${GlobalConfig.NOTEBOOK_URL}/api`,
  getContext: () => AccountStore.getApiContext(),
  getToken: () => getToken(AccountStore.getWorkspaceId()),
});
