import type { App } from 'vue';
import {
  type TrackerModule,
  type IdentifyProps,
  type IdentifyPropsWithCompany,
  TRACKER_MODULE_TYPES,
} from '../types';

const env = window.__VITE_META_ENV_MODE__;

export class KrtTracker implements TrackerModule {
  moduleType = TRACKER_MODULE_TYPES.KARTE;
  krt: any;
  talkInitialized: boolean = false;
  constructor(tagUrl: string) {
    InsertTag(tagUrl);
    this.krt = (window as any).krt;
  }
  init(app: App<any>): void {
    // do nothing
  }

  identify(properties: IdentifyProps): void {
    this.krt('send', 'identify', { ...properties, env });
  }

  identifyWithCompany({ id, name, email, company }: IdentifyPropsWithCompany): void {
    const isInternalUser = email.endsWith('@codatum.dev');

    this.krt('send', 'identify', {
      user_id: id,
      name: name,
      email: email,
      company: company,
      env,
      isInternalUser,
    });
    this.krt('local', {
      wicle: {
        method: 'set_group',
        group_id: company.id,
      },
    });
  }
  view(): void {
    // do nothing
    this.krt('send', 'view', { env });
  }
  track(event: string, properties?: any): void {
    this.krt('send', event, { ...properties, env });
  }
  openChat(): void {
    // https://developers.karte.io/reference/krttalk
    // if (!this.talkInitialized) {
    //   this.krt('talk', 'boot', {
    //     language: 'en',
    //     env,
    //   });
    //   this.talkInitialized = true;
    // }
    // this.krt('talk', 'show', { env });
  }
}

function InsertTag(tagUrl: string) {
  // @ts-ignore
  !(function (n) {
    // @ts-ignore
    const o = (window[n] = function (...n) {
      // @ts-ignore
      return o.x ? o.x.apply(0, n) : o.q.push(n);
    });
    // @ts-ignore
    (o.q = []),
      // @ts-ignore
      (o.i = Date.now()),
      // @ts-ignore
      (o.allow = function () {
        // @ts-ignore
        o.o = 'allow';
      }),
      // @ts-ignore
      (o.deny = function () {
        // @ts-ignore
        o.o = 'deny';
      });
  })('krt');

  // insert tag in the last of head tag
  const tag = document.createElement('script');
  tag.type = 'text/javascript';
  tag.async = true;
  tag.src = tagUrl;
  const s = document.getElementsByTagName('script')[0];

  if (!s.parentNode) return;

  s.parentNode.insertBefore(tag, s);
}
