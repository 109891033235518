import './modules/datadog';
import { reactive, type App } from 'vue';
import { KrtTracker } from './modules/krt';
import { SegmentTracker } from './modules/segment';
import { IntercomTracker } from './modules/intercom';
import { GoogleAnalyticsTracker } from './modules/ga';

import {
  type TrackerModule,
  type IdentifyProps,
  type IdentifyPropsWithCompany,
  TRACKER_MODULE_TYPES,
} from './types';
import { CannyTracker } from './modules/canny';
import { GlobalConfig } from '@cdm/configs';
import AccountStore from '@cdm/domains/account/shared/stores/AccountStore';

const DEACTIVATE_TRACKER_COOKIE = 'cdm_tracker_deactivated';
const DEACTIVATE_PATH_PREFIXES = [
  '/public/', // public-link
  '/protected/', // integrated-notebook
];

class AnalyticsTracker {
  private modules: TrackerModule[] = [];
  state: {
    notificationCount: number;
  } = {
    notificationCount: 0,
  };

  init(app: App) {
    this.state = reactive(this.state);
    if (cookieExists(DEACTIVATE_TRACKER_COOKIE)) {
      this.modules = [];
      console.log('tracker deactivated');
      return;
    }
    if (DEACTIVATE_PATH_PREFIXES.some(prefix => window.location.pathname.startsWith(prefix))) {
      // global-link や integrated-notebook の場合は一旦 tracker をすべて無効化している
      // 個別に有効化する場合はここに条件を追加する
      return;
    }

    if (GlobalConfig.KRT_EDGE_TAG) {
      const krt = new KrtTracker(GlobalConfig.KRT_EDGE_TAG);
      this.modules.push(krt);
    }

    if (GlobalConfig.CANNY_APP_ID) {
      const canny = new CannyTracker(GlobalConfig.CANNY_APP_ID);
      this.modules.push(canny);
    }

    if (GlobalConfig.SEGMENT_WRITE_KEY) {
      const segmentTracker = new SegmentTracker(GlobalConfig.SEGMENT_WRITE_KEY);
      this.modules.push(segmentTracker);
    }

    if (GlobalConfig.GOOGLE_ANALYTICS_ID) {
      const ga = new GoogleAnalyticsTracker(GlobalConfig.GOOGLE_ANALYTICS_ID);
      this.modules.push(ga);
    }

    const intercomTracker = new IntercomTracker();
    this.modules.push(intercomTracker);
    intercomTracker.setUnreadCountChangedListener(count => {
      this.state.notificationCount = count;
    });

    this.modules.forEach(module => {
      module.init(app);
    });
  }

  identify({ id, name, email, intercomHash }: IdentifyProps) {
    this.modules.forEach(module => {
      module.identify({ id, name, email, intercomHash });
    });
  }

  identifyWithCompany({ id, name, email, company }: IdentifyPropsWithCompany) {
    this.modules.forEach(module => {
      module.identifyWithCompany({ id, name, email, company });
    });
  }

  view() {
    this.modules.forEach(module => {
      module.view();
    });
  }

  track(event: string, properties?: any) {
    const wsId = AccountStore.getWorkspaceId();
    this.modules.forEach(module => {
      module.track(event, { ...properties, wsId });
    });
  }

  openChat() {
    this.modules.forEach(module => {
      module.openChat();
    });
  }

  getCannyModule() {
    return this.modules.find(m => m.moduleType === TRACKER_MODULE_TYPES.CANNY) as
      | CannyTracker
      | undefined;
  }
}

const cookieExists = (cookieName: string): boolean => {
  const cookies = document.cookie.split(';');
  const cookie = cookies.find(cookieStr => cookieStr.trim().startsWith(`${cookieName}=`));
  return !!cookie;
};

export default new AnalyticsTracker();
