const DEFAULT_STORE_ID = '_default_';

export class StoreManager<T> {
  private stores: { [key: string]: T };
  private cls: new () => T;

  constructor(cls: new () => T) {
    this.cls = cls;
    this.stores = {};
  }

  // MEMO: this を固定するために arrow 関数で定義（以下同様）
  get = (id: string = DEFAULT_STORE_ID): T => {
    let store = this.stores[id];
    if (!store) {
      store = new this.cls();
      this.stores[id] = store;
    }
    return store;
  };

  destroy = (id: string = DEFAULT_STORE_ID): void => {
    const store = this.stores[id];
    if (!store) return;
    delete this.stores[id];
  };
}

/** wsId 毎の store の管理者 */
export class StorePerWsIdManager<T> {
  private stores: { [wsId: string]: T };
  private cls: new (wsId: string) => T;

  constructor(cls: new (wsId: string) => T) {
    this.cls = cls;
    this.stores = {};
  }

  get = (wsId: string): T => {
    let store = this.stores[wsId];
    if (!store) {
      store = new this.cls(wsId);
      this.stores[wsId] = store;
    }
    return store;
  };

  exists = (wsId: string): boolean => {
    return !!this.stores[wsId];
  };

  destroy = (wsId: string): void => {
    const store = this.stores[wsId];
    if (!store) return;
    delete this.stores[wsId];
  };
}
