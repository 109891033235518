import type {
  ColorPalette,
  BuiltinColorPaletteKey,
} from '@cdm/@shared-server-notebook/types/color_palette';

export const BUILTIN_COLOR_PALETTES: { [key in BuiltinColorPaletteKey]: ColorPalette } = {
  DEFAULT: {
    DARK: {
      SUCCESS: '#4289E8',
      WARN: '#F8AE59',
      DANGER: '#F46666',
      SERIES1: '#4289E8',
      SERIES2: '#32CCE4',
      SERIES3: '#8199AE',
      SERIES4: '#F5ECD1',
      SERIES5: '#C4DBCF',
      SERIES6: '#FFC587',
      SERIES7: '#75C498',
      SERIES8: '#E0CE68',
      SERIES9: '#E0A843',
      SERIES10: '#E0835F',
      SERIES11: '#AE67E8',
      SERIES12: '#6A66E8',
      GRADIENT_HIGH: '#F46666',
      GRADIENT_MID: '#F8AE59',
      GRADIENT_LOW: '#FFF086',
    },
    LIGHT: {
      SUCCESS: '#3684E3',
      WARN: '#F7A13A',
      DANGER: '#DD4C4C',
      SERIES1: '#3684E3',
      SERIES2: '#28C1DB',
      SERIES3: '#728EA2',
      SERIES4: '#F5E9C7',
      SERIES5: '#B9D2C7',
      SERIES6: '#FFBB7E',
      SERIES7: '#6BBA8C',
      SERIES8: '#DAC457',
      SERIES9: '#DA9A2F',
      SERIES10: '#D9784C',
      SERIES11: '#9C56DE',
      SERIES12: '#5A50E1',
      GRADIENT_HIGH: '#DD4C4C',
      GRADIENT_MID: '#F7A13A',
      GRADIENT_LOW: '#FAEC69',
    },
  },
  MONOCHROME: {
    DARK: {
      SUCCESS: '#545454',
      WARN: '#7F7F7F',
      DANGER: '#A9A9A9',
      SERIES1: '#E6E6E6',
      SERIES2: '#B5B5B5',
      SERIES3: '#9A9A9A',
      SERIES4: '#7E7E7E',
      SERIES5: '#656565',
      SERIES6: '#4D4D4D',
      SERIES7: '#E6E6E6',
      SERIES8: '#B5B5B5',
      SERIES9: '#9A9A9A',
      SERIES10: '#7E7E7E',
      SERIES11: '#656565',
      SERIES12: '#4D4D4D',
      GRADIENT_HIGH: '#E6E6E6',
      GRADIENT_MID: '#7E7E7E',
      GRADIENT_LOW: '#4D4D4D',
    },
    LIGHT: {
      SUCCESS: '#C2C2C2',
      WARN: '#9A9A9A',
      DANGER: '#5E5E5E',
      SERIES1: '#111111',
      SERIES2: '#454545',
      SERIES3: '#707070',
      SERIES4: '#A4A4A4',
      SERIES5: '#CECECE',
      SERIES6: '#EEEEEE',
      SERIES7: '#111111',
      SERIES8: '#454545',
      SERIES9: '#707070',
      SERIES10: '#A4A4A4',
      SERIES11: '#CECECE',
      SERIES12: '#EEEEEE',
      GRADIENT_HIGH: '#111111',
      GRADIENT_MID: '#A4A4A4',
      GRADIENT_LOW: '#EEEEEE',
    },
  },
  PASTEL: {
    DARK: {
      SUCCESS: '#8EB4EB',
      WARN: '#F5D2B0',
      DANGER: '#EB8E8E',
      SERIES1: '#AE9EEC',
      SERIES2: '#ACC5EE',
      SERIES3: '#F5D2B0',
      SERIES4: '#B5E0B0',
      SERIES5: '#EEB5B5',
      SERIES6: '#EEEAB5',
      SERIES7: '#AE9EEC',
      SERIES8: '#ACC5EE',
      SERIES9: '#F5D2B0',
      SERIES10: '#B5E0B0',
      SERIES11: '#EEB5B5',
      SERIES12: '#EEEAB5',
      GRADIENT_HIGH: '#EB8E8E',
      GRADIENT_MID: '#F5D2B0',
      GRADIENT_LOW: '#FDEFBB',
    },
    LIGHT: {
      SUCCESS: '#8EAFE6',
      WARN: '#F7C79E',
      DANGER: '#E68E8E',
      SERIES1: '#A292EC',
      SERIES2: '#A5C8E6',
      SERIES3: '#F7C79E',
      SERIES4: '#B1D9A9',
      SERIES5: '#EAABA4',
      SERIES6: '#ECE7B1',
      SERIES7: '#A292EC',
      SERIES8: '#A5C8E6',
      SERIES9: '#F7C79E',
      SERIES10: '#B1D9A9',
      SERIES11: '#EAABA4',
      SERIES12: '#ECE7B1',
      GRADIENT_HIGH: '#E68E8E',
      GRADIENT_MID: '#F7C79E',
      GRADIENT_LOW: '#F7F4B2',
    },
  },
  AQUA: {
    DARK: {
      SUCCESS: '#4289E8',
      WARN: '#F8AE59',
      DANGER: '#F46666',
      SERIES1: '#40AEA8',
      SERIES2: '#46CFE4',
      SERIES3: '#85AEB0',
      SERIES4: '#D8F5F5',
      SERIES5: '#B9DFE9',
      SERIES6: '#FFB08A',
      SERIES7: '#45D485',
      SERIES8: '#98DBFA',
      SERIES9: '#5499CF',
      SERIES10: '#E66969',
      SERIES11: '#B49AE8',
      SERIES12: '#00A6A6',
      GRADIENT_HIGH: '#F46666',
      GRADIENT_MID: '#F8AE59',
      GRADIENT_LOW: '#FFF086',
    },
    LIGHT: {
      SUCCESS: '#3684E3',
      WARN: '#F7A13A',
      DANGER: '#DD4C4C',
      SERIES1: '#43A6A1',
      SERIES2: '#45C6DA',
      SERIES3: '#709395',
      SERIES4: '#CDECEC',
      SERIES5: '#A9CFDA',
      SERIES6: '#FFA07A',
      SERIES7: '#3CB371',
      SERIES8: '#87CEEB',
      SERIES9: '#4682B4',
      SERIES10: '#CD5C5C',
      SERIES11: '#A28BD2',
      SERIES12: '#008B8B',
      GRADIENT_HIGH: '#DD4C4C',
      GRADIENT_MID: '#F7A13A',
      GRADIENT_LOW: '#FAEC69',
    },
  },
  EARTH: {
    DARK: {
      SUCCESS: '#4289E8',
      WARN: '#F8AE59',
      DANGER: '#F46666',
      SERIES1: '#F87559',
      SERIES2: '#FFAE5E',
      SERIES3: '#8C8C8C',
      SERIES4: '#F2C894',
      SERIES5: '#D1CB78',
      SERIES6: '#FFA588',
      SERIES7: '#82A34C',
      SERIES8: '#E69647',
      SERIES9: '#9ED49E',
      SERIES10: '#A6681C',
      SERIES11: '#426666',
      SERIES12: '#BC3636',
      GRADIENT_HIGH: '#F46666',
      GRADIENT_MID: '#F8AE59',
      GRADIENT_LOW: '#FFF086',
    },
    LIGHT: {
      SUCCESS: '#3684E3',
      WARN: '#F7A13A',
      DANGER: '#DD4C4C',
      SERIES1: '#DD634C',
      SERIES2: '#F69F54',
      SERIES3: '#696969',
      SERIES4: '#DEB887',
      SERIES5: '#BDB76B',
      SERIES6: '#E9967A',
      SERIES7: '#6C8540',
      SERIES8: '#CD853F',
      SERIES9: '#8FBC8F',
      SERIES10: '#8B5613',
      SERIES11: '#2F4F4F',
      SERIES12: '#A02D2D',
      GRADIENT_HIGH: '#DD4C4C',
      GRADIENT_MID: '#F7A13A',
      GRADIENT_LOW: '#FAEC69',
    },
  },
  MUTED: {
    DARK: {
      SUCCESS: '#7489C8',
      WARN: '#C88F74',
      DANGER: '#C87474',
      SERIES1: '#A8A8B6',
      SERIES2: '#95B8C7',
      SERIES3: '#F3ECD2',
      SERIES4: '#CEB488',
      SERIES5: '#C88C74',
      SERIES6: '#D1D485',
      SERIES7: '#A8A8B6',
      SERIES8: '#95B8C7',
      SERIES9: '#F3ECD2',
      SERIES10: '#CEB488',
      SERIES11: '#C88C74',
      SERIES12: '#D1D485',
      GRADIENT_HIGH: '#C87474',
      GRADIENT_MID: '#C88F74',
      GRADIENT_LOW: '#DAC187',
    },
    LIGHT: {
      SUCCESS: '#6E91DD',
      WARN: '#DD976E',
      DANGER: '#DD6E6E',
      SERIES1: '#8E8E9C',
      SERIES2: '#7B9EAD',
      SERIES3: '#D6D1A5',
      SERIES4: '#B49A6E',
      SERIES5: '#BC7E65',
      SERIES6: '#B7BA6B',
      SERIES7: '#8E8E9C',
      SERIES8: '#7B9EAD',
      SERIES9: '#D6D1A5',
      SERIES10: '#B49A6E',
      SERIES11: '#BC7E65',
      SERIES12: '#B7BA6B',
      GRADIENT_HIGH: '#DD6E6E',
      GRADIENT_MID: '#DD976E',
      GRADIENT_LOW: '#F2E387',
    },
  },
  VIVID: {
    DARK: {
      SUCCESS: '#4280F2',
      WARN: '#FF892F',
      DANGER: '#DE4141',
      SERIES1: '#FF892F',
      SERIES2: '#DE4141',
      SERIES3: '#49C3FF',
      SERIES4: '#FFD742',
      SERIES5: '#49E7E7',
      SERIES6: '#8842FF',
      SERIES7: '#42D66A',
      SERIES8: '#4266FF',
      SERIES9: '#FF42D6',
      SERIES10: '#DF72FE',
      SERIES11: '#FF4280',
      SERIES12: '#FF6642',
      GRADIENT_HIGH: '#DE4141',
      GRADIENT_MID: '#FF892F',
      GRADIENT_LOW: '#FFF57C',
    },
    LIGHT: {
      SUCCESS: '#337EFF',
      WARN: '#FF8228',
      DANGER: '#EE3737',
      SERIES1: '#FF8228',
      SERIES2: '#EE3737',
      SERIES3: '#51C6FF',
      SERIES4: '#FFD23D',
      SERIES5: '#49EEEE',
      SERIES6: '#7733FF',
      SERIES7: '#33CC55',
      SERIES8: '#3355FF',
      SERIES9: '#FF33CC',
      SERIES10: '#E599FF',
      SERIES11: '#FF3377',
      SERIES12: '#FF5533',
      GRADIENT_HIGH: '#EE3737',
      GRADIENT_MID: '#FF8228',
      GRADIENT_LOW: '#FFF26F',
    },
  },
};
