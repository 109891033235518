import { computed } from 'vue';
import AccountStore from '@cdm/domains/account/shared/stores/AccountStore';

export function useCurrentWorkspaceId() {
  const currentWorkspaceId = computed(() => AccountStore.getWorkspaceId() || '');
  return currentWorkspaceId;
}

export function useCurrentWorkspace() {
  const workspace = computed(() => AccountStore.getWorkspace());

  function refreshWorkspace() {
    AccountStore.refreshWorkspace();
  }

  return {
    workspace,
    refreshWorkspace,
  };
}
