//  eslint-disable-next-line no-restricted-imports
import { Message as ArcoMessage, type MessageConfig } from '@arco-design/web-vue';
import { ApiError as NodeApiError } from '@cdm/@shared-server-notebook/endpoints/client';
import { ApiError as GoApiError } from '@cdm/clients/fetcher';

const DEFAULT_CONFIG: Partial<MessageConfig> = {
  duration: 1000 * 3,
  closable: true,
  position: 'top',
  resetOnHover: true, // mouseover すると自動クローズを無効化できるらしい
};

class CustomMessage {
  private _prepareMessage(message: string | MessageConfig): MessageConfig {
    const config: MessageConfig = typeof message === 'string' ? { content: message } : message;
    Object.keys(DEFAULT_CONFIG).forEach(key => {
      const _key = key as keyof MessageConfig;
      if (config[_key] === undefined) {
        // 未指定のものだけデフォルト値で上書き
        // @ts-expect-error
        config[_key] = DEFAULT_CONFIG[_key];
      }
    });
    return config;
  }

  public info(message: string | MessageConfig) {
    const _message = this._prepareMessage(message);
    return ArcoMessage.info(_message);
  }

  public success(message: string | MessageConfig) {
    const _message = this._prepareMessage(message);
    return ArcoMessage.success(_message);
  }

  public error(message: string | MessageConfig) {
    const _message = this._prepareMessage(message);
    _message.duration = 1000 * 10; // エラーの場合は長めに表示
    _message.closable = true;
    return ArcoMessage.error(_message);
  }

  public loading(message: string | MessageConfig) {
    const _message = this._prepareMessage(message);
    return ArcoMessage.loading(_message);
  }

  public clear() {
    return ArcoMessage.clear();
  }

  public apiError(err: Error | unknown, fallbackMessage: string) {
    if (err instanceof GoApiError) {
      if (400 <= err.status && err.status < 500) {
        return this.error(err.message);
      }
    }

    if (err instanceof NodeApiError) {
      if (400 <= err.status && err.status < 500) {
        return this.error(err.message);
      }
    }

    return this.error(fallbackMessage);
  }
}

export const Message = new CustomMessage();
