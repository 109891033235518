import type {
  ColorPaletteKey,
  PaletteColors,
  PaletteTheme,
  ColorPalette,
  BuiltinColorPaletteKey,
} from '@cdm/@shared-server-notebook/types/color_palette';
import { reactive } from 'vue';
import {
  BUILTIN_COLOR_PALETTE_KEYS,
  BUILTIN_COLOR_PALETTE_OPTIONS,
  PALETTE_COLOR_NAMES,
  PALETTE_THEMES,
} from '@cdm/@shared-server-notebook/constants/color_palette';
import type { CustomPalette } from '@cdm/@shared-server-notebook/types/workspace-setting';
import { cloneDeep } from 'lodash-es';
import { BUILTIN_COLOR_PALETTES } from './BuiltinColorPalettes';
import {
  getWorkspaceSettingStore,
  type WorkspaceSetting,
} from '@cdm/domains/workspace/shared/stores/WorkspaceSettingStore';
import { generateObjectId } from '@cdm/utils/objectId';
import { notNullFilter } from '@cdm/utils/notNullFilter';

export const THEME_NAMES = [PALETTE_THEMES.DARK, PALETTE_THEMES.LIGHT] as const;

export const SERIES_NAMES = [
  PALETTE_COLOR_NAMES.SERIES1,
  PALETTE_COLOR_NAMES.SERIES2,
  PALETTE_COLOR_NAMES.SERIES3,
  PALETTE_COLOR_NAMES.SERIES4,
  PALETTE_COLOR_NAMES.SERIES5,
  PALETTE_COLOR_NAMES.SERIES6,
  PALETTE_COLOR_NAMES.SERIES7,
  PALETTE_COLOR_NAMES.SERIES8,
  PALETTE_COLOR_NAMES.SERIES9,
  PALETTE_COLOR_NAMES.SERIES10,
  PALETTE_COLOR_NAMES.SERIES11,
  PALETTE_COLOR_NAMES.SERIES12,
] as const;

export const GRADIENT_NAMES = [
  PALETTE_COLOR_NAMES.GRADIENT_HIGH,
  PALETTE_COLOR_NAMES.GRADIENT_MID,
  PALETTE_COLOR_NAMES.GRADIENT_LOW,
] as const;

export const SEMANTIC_NAMES = [
  PALETTE_COLOR_NAMES.SUCCESS,
  PALETTE_COLOR_NAMES.WARN,
  PALETTE_COLOR_NAMES.DANGER,
] as const;

class ColorPaletteStore {
  private state: {
    wsId: string;
    storeWatchingWsId: string;
    customPalettes: CustomPalette[];
    embeddedPalette: CustomPalette | null;

    theme: PaletteTheme;
    paletteKey: ColorPaletteKey;
    colors: PaletteColors;
  };

  constructor() {
    this.state = reactive({
      wsId: '',
      storeWatchingWsId: '',
      customPalettes: [],
      embeddedPalette: null,

      theme: null as any,
      paletteKey: null as any,
      colors: null as any,
    });
  }

  // 一番先に呼ぶ
  public setWorkspaceId(wsId: string) {
    if (this.state.wsId !== wsId) {
      this.state.wsId = wsId;
      this.state.customPalettes = [];
      if (this.state.storeWatchingWsId) {
        getWorkspaceSettingStore(this.state.storeWatchingWsId).off(
          'workspaceSettingUpdated',
          this._onWorkspaceSettingUpdated,
        );
      }
    }
  }

  private _onWorkspaceSettingUpdated = (setting: WorkspaceSetting) => {
    this.state.customPalettes = setting.color_palette.custom_palettes;
  };

  public setTheme(theme: PaletteTheme) {
    this.state.theme = theme;
    this.state.paletteKey = BUILTIN_COLOR_PALETTE_KEYS.DEFAULT;
    this._buildColors();
  }

  public async fetchCustomPalettes() {
    if (!this.state.wsId) {
      throw new Error('wsId is not set');
    }
    const store = getWorkspaceSettingStore(this.state.wsId);
    this.state.storeWatchingWsId = this.state.wsId;
    store.on('workspaceSettingUpdated', this._onWorkspaceSettingUpdated);
    if (store.state.initialized) {
      this.state.customPalettes = store.state.setting?.color_palette.custom_palettes || [];
    } else {
      await new Promise(resolve => {
        store.once('workspaceSettingUpdated', () => {
          resolve(null);
        });
      });
    }
  }

  // palette のプレビュー用
  public createClone(theme: PaletteTheme, paletteKey: ColorPaletteKey): ColorPaletteStore {
    const clone = new ColorPaletteStore();
    clone.state = {
      ...cloneDeep(this.state),
      theme,
      paletteKey,
    };
    clone._buildColors();
    return clone;
  }

  // setTheme 前に色の取得が実行された時にエラーにならないようにとりあえず初期化する
  private _checkOrInitialize() {
    let isInitialized = true;
    if (!this.state.theme) {
      this.state.theme = PALETTE_THEMES.DARK;
      isInitialized = false;
    }
    if (!this.state.paletteKey) {
      this.state.paletteKey = BUILTIN_COLOR_PALETTE_KEYS.DEFAULT;
      isInitialized = false;
    }
    if (!isInitialized) {
      this._buildColors();
    }
  }

  private _buildColors() {
    if (this.state.embeddedPalette) {
      this.state.colors = this.state.embeddedPalette.palette[this.state.theme];
    } else {
      this.state.colors = this._getColors(this.state.theme, this.state.paletteKey);
    }
  }

  public createCustomPaletteSource(paletteKey: ColorPaletteKey): CustomPalette {
    const palette: Partial<CustomPalette['palette']> = {};
    for (const theme of Object.values(PALETTE_THEMES)) {
      palette[theme] = this._getColors(theme, paletteKey);
    }
    return {
      _id: generateObjectId(),
      name: '',
      palette: palette as ColorPalette,
    };
  }

  private _getColors(theme: PaletteTheme, paletteKey: ColorPaletteKey): PaletteColors {
    if (BUILTIN_COLOR_PALETTE_KEYS[paletteKey as BuiltinColorPaletteKey]) {
      return BUILTIN_COLOR_PALETTES[paletteKey as BuiltinColorPaletteKey][theme];
    }
    const customPalette = this.state.customPalettes.find(p => p._id === paletteKey);
    if (customPalette) {
      return customPalette.palette[theme];
    }

    // デフォルト
    const colors: PaletteColors = {
      ...BUILTIN_COLOR_PALETTES[BUILTIN_COLOR_PALETTE_KEYS.DEFAULT][theme],
    };
    return colors;
  }

  // 外部で color をキャッシュする時に使う
  public getCacheKey(): string {
    return `${this.state.theme}:${this.state.paletteKey}`;
  }

  public setPaletteKey(
    paletteKey: ColorPaletteKey,
    paletteEmbedded?: CustomPalette['palette'] | null,
  ) {
    this.state.paletteKey = paletteKey;
    // paletteEmbedded がある場合はそれを優先する
    if (paletteEmbedded) {
      this.state.embeddedPalette = {
        _id: paletteKey,
        name: '(Embedded palette)',
        palette: paletteEmbedded,
      };
    } else {
      this.state.embeddedPalette = null;
    }
    this._buildColors();
  }

  public getSemanticColors(): { SUCCESS: string; WARN: string; DANGER: string } {
    this._checkOrInitialize();
    return {
      SUCCESS: this.state.colors.SUCCESS,
      WARN: this.state.colors.WARN,
      DANGER: this.state.colors.DANGER,
    };
  }

  public getSeriesColors(): string[] {
    this._checkOrInitialize();
    const c = this.state.colors;
    return [
      c.SERIES1,
      c.SERIES2,
      c.SERIES3,
      c.SERIES4,
      c.SERIES5,
      c.SERIES6,
      c.SERIES7,
      c.SERIES8,
      c.SERIES9,
      c.SERIES10,
      c.SERIES11,
      c.SERIES12,
    ].filter(c => !!c);
  }

  public getGradientColors(): string[] {
    this._checkOrInitialize();
    const c = this.state.colors;
    return [c.GRADIENT_HIGH, c.GRADIENT_MID, c.GRADIENT_LOW];
  }

  public getPaletteLabel(paletteKey: ColorPaletteKey): string {
    const custom = this.state.customPalettes.find(p => p._id === paletteKey);
    if (custom) {
      return custom.name;
    }
    const builtin = BUILTIN_COLOR_PALETTE_OPTIONS.find(p => p.key === paletteKey);
    if (builtin) {
      return builtin.label;
    }
    return paletteKey;
  }

  public getPaletteGroupedOptions(): {
    label: string;
    options: { label: string; value: ColorPaletteKey }[];
  }[] {
    return [
      {
        label: 'Builtin',
        options: BUILTIN_COLOR_PALETTE_OPTIONS.map(opt => ({ label: opt.label, value: opt.key })),
      },
      this.state.customPalettes.length > 0
        ? {
            label: 'Custom',
            options: this.state.customPalettes.map(opt => ({
              label: opt.name,
              value: opt._id,
            })),
          }
        : null,
    ].filter(notNullFilter);
  }
}

// singleton
const colorPaletteStore = new ColorPaletteStore();
export { colorPaletteStore };
